<template>
  <div class="list-area">
    <div v-if="showArea === 'list'" class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.appCategoryId"
            placeholder="应用类型"
          >
            <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="searchData.appCategoryId === 1">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.subType"
            placeholder="子类型"
          >
            <el-option
              v-for="item in appSubTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.reviewStatus"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in versionAduitList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.sourceType"
            placeholder="创建来源"
          >
            <el-option
              v-for="item in sourceList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
              v-show="item.key !== 1"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-input
            size="small"
            placeholder="请输入搜索内容"
            v-model="serachKeyword"
            class="input-with-select"
          >
            <el-select
              size="small"
              slot="prepend"
              v-model="serachKeywordCode"
              placeholder="请选择类型"
            >
              <!-- <el-option label="APPID" :value="1"></el-option> -->
              <el-option label="应用名称" :value="2"></el-option>
              <el-option label="应用包名" :value="3"></el-option>
            </el-select>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              size="small"
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4"
          ><el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          ></el-col
        >
      </el-row>
      <PageTable
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column
          width="55"
          slot="prefix"
          type="selection"
          :selectable="selectableRow"
        >
        </el-table-column> -->
        <template slot-scope="{ row }" slot="appId">
          <div>
            <div class="urge-trial" v-if="row.isNotify == 'N'">催审</div>
            {{ row.appId }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="appCategoryId">{{
          row.appCategoryId | typeDesc(appTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="sourceType">{{
          row.sourceType | typeDesc(sourceList)
        }}</template>
        <template slot-scope="{ row }" slot="releaseStatus">
          <el-tag
            size="small"
            :type="row.releaseStatus | tagType(appReviewStatusList)"
          >
            {{ row.releaseStatus | typeDesc(appReviewStatusList) }}
          </el-tag>
        </template>
        <el-table-column
          label="操作"
          width="150px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              v-has="'lookinfo'"
              @click="handleOperate('view', row)"
            >
              <span class="colorGreen">查看</span>
            </el-button>
            <el-button
              type="text"
              v-has="'downloadAPK'"
              @click="handleOperate('download', row)"
              :disabled="!row.filePath"
              >下载apk</el-button
            >
            <el-button
              type="text"
              class="ml10"
              v-has="'review'"
              slot="reference"
              @click="handleOperate('review', row)"
              :disabled="!reviewList.includes(row.status)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
      <!-- <div class="page-foot" v-has="'reviewBatch'">
        <el-select
          v-model="batchOperation"
          size="small"
          class="mr10"
          placeholder="批量操作"
        >
          <el-option :value="1" label="审核通过"></el-option>
          <el-option :value="2" label="审核不通过"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="handleBatchOperation"
          >提交操作</el-button
        >
      </div> -->
    </div>
    <Detail
      v-if="showArea === 'detail'"
      :operationType="operationType"
      :detailData="detailData"
      :releaseType="detailData.releaseType"
      :languageList="languageList"
      @cancle="handleCancel('list')"
    />
    <el-dialog
      center
      destroy-on-close
      :title="detailData.status | dialogTitle"
      :visible.sync="dialogAuditVisible"
    >
      <auditModel
        :detailData="detailData"
        @submit="handleSubmitAudit"
      ></auditModel>
    </el-dialog>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import Detail from "./Detail.vue";
import auditModel from "./auditModel.vue";
import {
  sourceList,
  appReviewStatusList,
  appTypeList,
  versionAduitList,
  appSubTypeList,
} from "@/common/constant/constant.js";
import { recordUrl } from "@/common/js/commonApi.js";
import {
  appVersionAudit,
  getReviewList,
  queryGeneralDiff,
  appVersionAuditBatch,
  getLanguageList,
  getBackInfo,
  getOffShelfInfo,
} from "./indexServe";
import moment from "moment";
export default {
  name: "versionCheck",
  components: { PageTable, Detail, auditModel },
  mounted() {
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      sourceList,
      appTypeList,
      versionAduitList,
      appSubTypeList,
      appReviewStatusList,
      languageList: [],
      searchData: {
        date: [start, end],
      },
      serachKeyword: "",
      serachKeywordCode: 2,
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "appId",
          label: "APPID",
          width: 100,
          fixed: "left",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "appName",
          label: "应用名称",
          fixed: "left",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "packageName",
          label: "应用包名",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "appCategoryId",
          label: "应用类型",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "versionName",
          label: "版本",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "fileSize",
          label: "包体大小",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "submitTime",
          label: "提交时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          key: "auditTime",
          label: "审核时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          slot: "sourceType",
          label: "创建来源",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "auditor",
          label: "审核员",
          align: "center",
          "show-overflow-tooltip": true,
        },
        // { slot: "releaseStatus", label: "审核状态" },
        {
          key: "statusDesc",
          label: "审核状态",
          align: "center",
          width: 120,
          "show-overflow-tooltip": true,
        },
      ],
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
      batchOperation: "",
      dialogAuditVisible: false,
      showArea: "list", // 记录当前显示的区域，列表-list，应用详情-detail
      reviewList: [100, 230, 600, 700, 400, 500], // 可审核的状态列表
    };
  },
  filters: {
    dialogTitle(status) {
      switch (status) {
        case 400:
          return "回退审核";
        case 500:
          return "下架审核";

        default:
          return "版本审核";
      }
    },
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleOperate(operationType, row) {
      this.operationType = operationType;
      if (["view"].includes(operationType)) {
        this.showArea = "detail";
        const detailData = await this.getDetail({ releaseId: row.releaseId });
        this.detailData = {
          releaseType: parseInt(row.releaseType),
          appReleaseId: row.releaseId,
          sourceType: row.sourceType, //来源类型(0开发者平台 1运营平台)
          appId: row.appId,
          id: row.id,
          ...detailData,
        };
      } else if (operationType === "review") {
        this.dialogAuditVisible = true;
        let infoData = {};
        //回退待审核--400,下架待审核--500
        if (row.status === 400) {
          infoData = await this.getBackInfo(row);
        } else if (row.status === 500) {
          infoData = await this.getOffShelfInfo(row);
        }
        this.detailData = { ...infoData, ...row };
      } else if (operationType === "download") {
        this.recordUrl(row.filePath);
      }
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    selectableRow(row) {
      if (!this.reviewList.includes(row.status)) return false;
      return true;
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.releaseId);
    },
    handleBatchOperation() {
      if (this.selectionIdList.length == 0 || this.batchOperation === "") {
        const msg =
          this.batchOperation === ""
            ? "请选择操作类型"
            : "请勾选需要操作的数据";
        this.$message.error(msg);
        return;
      }
      appVersionAuditBatch({
        appIdList: this.selectionIdList,
        reviewResult: this.batchOperation,
      }).then((res) => {
        if (res && res.code === 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success(res.data);
        }
      });
    },
    handleSubmitAudit(formData) {
      const data = {
        appReleaseId: this.detailData.releaseId,
        ...formData,
      };
      appVersionAudit(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleCancel(showArea) {
      this.showArea = showArea;
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.startTime = date[0];
        data.endTime = date[1];
      }
      if (this.serachKeyword) {
        data.chooseType = this.serachKeywordCode;
        data.keyword = this.serachKeyword;
      }
      const res = await getReviewList(data);
      if (res && res.code === 0) {
        this.tableData = res.data;
      }
    },
    // 获取详情
    async getDetail(params) {
      const res = await queryGeneralDiff(params);
      let detailData = {};
      if (res && res.code === 0) {
        detailData = res.data;
      }
      return detailData;
    },
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    // 获取回退信息
    async getBackInfo(row) {
      let languageData = {};
      const res = await getBackInfo({ appReleaseId: row.releaseId });
      if (res && res.code === 0) {
        languageData = res.data;
      }
      return languageData;
    },
    // 获取下架信息
    async getOffShelfInfo(row) {
      let languageData = {};
      const res = await getOffShelfInfo({ appReleaseId: row.releaseId });
      if (res && res.code === 0) {
        languageData = res.data;
      }
      return languageData;
    },
    // 记录下载行为
    async recordUrl(downUrl) {
      const res = await recordUrl({ downUrl });
      if (res && res.code === 0) {
        window.open(downUrl);
      }
    },
    // 其他
    init() {
      this.getTableList();
      this.getLanguageList();
    },
  },
};
</script>
<style scoped lang="scss">
.input-with-select {
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
}
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
  .urge-trial {
    width: 35px;
    height: 20px;
    background-color: #319518;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
